import { HTMLtoJSX } from '../../../../../../helpers';
import {
  HubModule,
  PropsWithTestId,
  TitleSizeType,
} from '../../../../../../types';
import { HubModuleTypes } from '../../../../../../constants';
import { ModuleHeader } from '../../components';
import { HubModuleWrapper } from '../../HubModuleWrapper';

export interface HtmlBlockModuleType extends HubModule {
  '@type': HubModuleTypes.HtmlBlock;
  title: string;
  titleSize: TitleSizeType;
  text: string;
  html: string;
}

export interface HtmlModuleProps {
  module: HtmlBlockModuleType;
}

export default function HtmlBlockModule(
  props: PropsWithTestId<HtmlModuleProps>,
) {
  const {
    testId,
    module: { '@type': type, title, titleSize, text, html, elementId },
  } = props;
  return (
    <HubModuleWrapper type={type} elementId={elementId} testId={testId}>
      <div className="container">
        <div className="row middle-md">
          <div className="col-xs-12" style={{ marginBottom: '36px' }}>
            <ModuleHeader
              title={title}
              titleSize={titleSize}
              text={text}
              lineAfterHeading
            />
          </div>
          <div className="col-xs-12">{HTMLtoJSX(html)}</div>
        </div>
      </div>
    </HubModuleWrapper>
  );
}
