import { PricingProductInput } from '../../../store/features/products/types';

export function getPreselectedVirtualVatValues(inputs: PricingProductInput[]) {
  return inputs.reduce((defaultValues, input) => {
    defaultValues[input.name] =
      input.type === 'CHECKBOX' ? input.value === 'true' : input.value;

    return defaultValues;
  }, {} as Record<string, string | boolean>);
}
