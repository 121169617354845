import i18next from 'i18next';
import { FIRSTNAME_LASTNAME, FORENAME_SURNAME } from '../../constants';

type FieldType = 'firstname' | 'lastname';

export const getNameFormatTranslation = (
  nameFormat: typeof FIRSTNAME_LASTNAME | typeof FORENAME_SURNAME,
  type: FieldType,
  required = false,
) => {
  const asterisk = required ? ' *' : '';
  const key = `global.name-format.${nameFormat}.${type}.label`;
  return `${i18next.t(key)}${asterisk}`;
};
