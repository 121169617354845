import {
  PricingProduct,
  PricingProducts,
  PricingProductType,
} from '../../../store/features/products/types';
import { UniqueIdentifier } from '../../../../../types';
import { PricingProductSelectionTypes } from '../../../types/enums';
import {
  ProductsSelections,
  PricingPackagesFormValues,
} from '../../../store/features/packages/types';
import { getRelatedPrimaryProductsData } from '../getRelatedPrimaryProducts';
import { getRelatedSecondaryProducts } from '../getRelatedSecondaryProducts';
import { getPreselectedVirtualVatValues } from '../getPreselectedVirtualVatValues';

export interface GetInitialPackageFormValuesParams {
  initialPackageFormValues: PricingPackagesFormValues;
  productsData: PricingProducts;
  selectedPrimaryProduct?: PricingProduct;
}

export const defaultPackageFormValues: PricingPackagesFormValues = {
  quantity: 1,
  productsSelections: {
    productMultipleIds: [],
    productSingleIdsMap: {},
    optionsMultipleMap: {},
    optionsSingleMap: {},
  },
};

export function getPreselectedPackageFormValues(
  params: GetInitialPackageFormValuesParams,
) {
  const {
    initialPackageFormValues,
    productsData: {
      productTypesWithProducts,
      optionalProductTypesWithProducts,
      vatForm: { vatInputs = [] } = {},
    },
    selectedPrimaryProduct,
  } = params;

  const primaryProduct =
    selectedPrimaryProduct ||
    findFirstPreselectedPrimaryProduct(productTypesWithProducts);
  const defaultVatFormValues = getPreselectedVirtualVatValues(vatInputs);

  const formValues: PricingPackagesFormValues = {
    // don't change the order (initialPackageFormValues should be at the end)
    ...defaultPackageFormValues,
    ...defaultVatFormValues,
    ...initialPackageFormValues,
  };

  if (primaryProduct?.id) {
    const preselectedProductsValues = getPreselectedProductsValues(
      primaryProduct,
      productTypesWithProducts,
      optionalProductTypesWithProducts,
    );

    formValues.productsSelections = {
      ...formValues.productsSelections,
      ...preselectedProductsValues,
    };
  }

  return formValues;
}

export function processPreselectedProductOptions(
  product: PricingProduct,
  optionsMultipleMap: ProductsSelections['optionsMultipleMap'],
  optionsSingleMap: ProductsSelections['optionsSingleMap'],
) {
  if (!product?.productOptionsList) {
    return;
  }

  product.productOptionsList.forEach((productOptionGroup, index) => {
    const isMultipleChoice =
      productOptionGroup.type === PricingProductSelectionTypes.MULTIPLE_CHOICE;
    const productIdWithIndex = `${product.id}-${index}`;
    const firstOption = productOptionGroup.options[0]?.id;
    const preselectedOptions = productOptionGroup.options.filter(
      (option) => option.preselected,
    );

    const optionsMap = isMultipleChoice ? optionsMultipleMap : optionsSingleMap;

    if (isMultipleChoice && preselectedOptions.length > 0) {
      optionsMap[productIdWithIndex] = preselectedOptions.map(
        (option) => option.id,
      );
    }

    if (!isMultipleChoice && firstOption) {
      // Single choice options don't have preselected values on admin side, so we should add always first option as preselected
      optionsMap[productIdWithIndex] = firstOption;
    }
  });
}

export function getAllProductsFromProductType(
  productTypes: PricingProductType[],
) {
  return productTypes.flatMap((productType: PricingProductType) => {
    if (!productType?.products?.length) return [];

    return productType.products;
  });
}

export function findFirstPreselectedPrimaryProduct(
  productTypesWithProducts: PricingProductType[],
) {
  const allPrimaryProducts = getAllProductsFromProductType(
    productTypesWithProducts,
  );

  return (
    allPrimaryProducts.find((product) => product.preselected) ||
    ({} as PricingProduct)
  );
}

export function getPreselectedProductsIds(products: PricingProduct[]) {
  return products
    .filter((product) => product.preselected)
    ?.map((preselectedProduct) => preselectedProduct.id);
}

export function getSingleProductIdsList(
  productSingleIdsMap: ProductsSelections['productSingleIdsMap'] = {},
) {
  return Object.values(productSingleIdsMap).filter(
    (id): id is UniqueIdentifier => !!id,
  );
}

export function getPreselectedProductsValues(
  selectedPrimaryProduct: PricingProduct,
  productTypesWithProducts: PricingProductType[],
  optionalProductTypesWithProducts: PricingProductType[],
) {
  const { products: relatedPrimaryProducts } = getRelatedPrimaryProductsData(
    productTypesWithProducts,
    selectedPrimaryProduct.productTypeId,
    selectedPrimaryProduct.id,
  );
  const relatedPrimaryProductsIds = getPreselectedProductsIds(
    relatedPrimaryProducts,
  );

  const productMultipleIds: ProductsSelections['productMultipleIds'] = [
    selectedPrimaryProduct.id,
    ...relatedPrimaryProductsIds,
  ];
  const productSingleIdsMap: ProductsSelections['productSingleIdsMap'] = {};
  const optionsMultipleMap: ProductsSelections['optionsMultipleMap'] = {};
  const optionsSingleMap: ProductsSelections['optionsSingleMap'] = {};

  [selectedPrimaryProduct, ...relatedPrimaryProducts].forEach(
    (primaryProduct) => {
      processPreselectedProductOptions(
        primaryProduct,
        optionsMultipleMap,
        optionsSingleMap,
      );
    },
  );

  optionalProductTypesWithProducts.forEach(
    (optionalProductType: PricingProductType) => {
      optionalProductType?.products?.forEach((secondaryProduct) => {
        const isMultipleChoice =
          optionalProductType.selectionType ===
          PricingProductSelectionTypes.MULTIPLE_CHOICE;
        const relatedSecondaryProducts = getRelatedSecondaryProducts(
          [secondaryProduct],
          [selectedPrimaryProduct.id, ...relatedPrimaryProductsIds],
        );

        if (!!relatedSecondaryProducts.length && secondaryProduct.preselected) {
          if (isMultipleChoice) {
            productMultipleIds.push(secondaryProduct.id);
          } else {
            productSingleIdsMap[optionalProductType.productTypeId] =
              secondaryProduct.id;
          }
        }

        processPreselectedProductOptions(
          secondaryProduct,
          optionsMultipleMap,
          optionsSingleMap,
        );
      });
    },
  );

  return {
    productMultipleIds,
    productSingleIdsMap,
    optionsMultipleMap,
    optionsSingleMap,
  };
}
