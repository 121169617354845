import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import Patterns from '../../utils/pattern/patterns';
import { hasValue, isBoolean } from '../index';

export function transformBooleanValuesToString(values, skipClone) {
  const isObjectValue = values && values.constructor === Object;

  let data = isObjectValue ? values : { value: values };
  data = skipClone ? data : cloneDeep(data);

  Object.keys(data).forEach((key) => {
    if (data[key] === true) {
      data[key] = 'true';
    } else if (data[key] === false) {
      data[key] = 'false';
    } else if (data[key]?.constructor === Object) {
      data[key] = transformBooleanValuesToString(data[key], true);
    }
  });

  return isObjectValue ? data : data.value;
}

export function transformBooleanLikeValuesToBoolean(values, skipClone) {
  const isObjectValue = values && values.constructor === Object;

  let data = isObjectValue ? values : { value: values };
  data = skipClone ? data : cloneDeep(data);

  Object.keys(data).forEach((key) => {
    if (data[key] === 'true') {
      data[key] = true;
    } else if (data[key] === 'false') {
      data[key] = false;
    } else if (data[key]?.constructor === Object) {
      data[key] = transformBooleanLikeValuesToBoolean(data[key], true);
    }
  });

  return isObjectValue ? data : data.value;
}

export function transformAdHocValues(values, prefix, adHoc) {
  const transformedValues = {};

  adHoc.sets.forEach((adHocSet) => {
    adHocSet.formInputs.forEach((field) => {
      const fieldName = getAdHocFieldName(field.name);
      const fullFieldName = getAdHocFieldName(field.name, prefix);

      switch (field.type) {
        case 'TEXT':
        case 'EMAIL':
        case 'TELEPHONE':
        case 'TEXTAREA':
        case 'SELECT':
        case 'DATE':
        case 'DATE_MM':
        case 'DATE_YY':
          {
            const value = get(values, fullFieldName);
            transformedValues[fieldName] = hasValue(value)
              ? [transformBooleanValuesToString({ value })]
              : [];
          }
          break;

        case 'FILE':
        case 'FILE_GROUP':
          transformedValues[fieldName] = get(values, fullFieldName, []);
          break;

        case 'RADIO':
          {
            const { __radio__: radioValue, __other__: other } = get(
              values,
              fullFieldName,
            );
            const value = radioValue !== '__checked__' ? radioValue : undefined;
            transformedValues[fieldName] =
              value || other ? [{ value, other }] : [];
          }
          break;

        case 'CHECKBOX_GROUP':
          {
            const {
              __checkbox__,
              __other__: other,
              ...checkboxes
            } = get(values, fullFieldName);

            Object.keys(checkboxes).forEach((fieldName) => {
              if (fieldNameHasSpecialCharacters(fieldName)) {
                // Create a new correct field name
                checkboxes[replaceFieldNameSpecialCharacters(fieldName)] =
                  checkboxes[fieldName];

                // Remove old redundant field name
                delete checkboxes[fieldName];
              }
            });

            const answers = Object.keys(checkboxes)
              .filter(
                (key) => checkboxes[key] === 'true' || checkboxes[key] === true,
              )
              .map((key) => ({
                value: key,
              }));

            if (other) {
              answers.push({
                other,
              });
            }
            transformedValues[fieldName] = answers;
          }
          break;

        default:
          break;
      }
    });
  });

  return {
    ...values,
    [prefix]: transformedValues,
  };
}

export function transformPermissionsValues(
  values,
  consentForms = [],
  prefix,
  { defaultConsents },
) {
  const transformedValues = [];

  consentForms.forEach((consentForm) => {
    const answer = {
      id: consentForm.id,
      answers: [],
    };

    if (values[prefix] && values[prefix][consentForm.id]) {
      Object.keys(values[prefix][consentForm.id]).forEach((channel) => {
        answer.answers.push({
          channel,
          value: values[prefix][consentForm.id][channel],
        });
      });
    }

    transformedValues.push(answer);
  });

  return {
    ...values,
    [prefix]: undefined,
    userMarketingPermissions: {
      defaultConsents,
      url: document.location.href,
      [prefix]: transformedValues,
    },
  };
}

export function getAdHocFieldName(name, adHocPrefix) {
  return adHocPrefix ? `${adHocPrefix}.${name}` : name;
}

export function getAdHocFilesFields(values, adHoc) {
  const returnedValues = {};

  adHoc.sets.forEach(({ formInputs }) => {
    formInputs.forEach(({ type, name }) => {
      if (['FILE', 'FILE_GROUP'].includes(type)) {
        returnedValues[name] = get(values, name);
      }
    });
  });

  return returnedValues;
}

export function uploadSelectedFilesAndTransformValues({ url, values, fields }) {
  const fileGroups = Object.keys(fields).map((fieldName) => {
    const files = fields[fieldName] || [];
    const filesToReturn = [];

    const body = new FormData();

    files.forEach((file) => {
      if (file.constructor === File) {
        body.append('files', file);
      } else {
        filesToReturn.push(file);
      }
    });

    if (filesToReturn.length === files.length) {
      return Promise.resolve({ fieldName, files });
    }

    return fetch(url, {
      method: 'POST',
      headers: {
        'Accept-Language': 'en',
      },
      body,
    })
      .then((response) => response.json())
      .then((response) => ({
        fieldName,
        files: [...filesToReturn, ...response],
      }))
      .catch(() => ({
        fieldName,
        files: filesToReturn,
      }));
  });

  return Promise.all(fileGroups).then((response) => {
    response.forEach(({ fieldName, files }) => {
      set(values, fieldName, files);
    });

    return values;
  });
}

export function getDelegateFilesFields(allFields, fileFieldsNames) {
  const returnedFields = {};

  fileFieldsNames.forEach((name) => {
    if (allFields[name]) {
      returnedFields[name] = allFields[name];
    }
  });

  return returnedFields;
}

export function transformValidationRules(
  { rules: validationRules, name },
  { t, getValues },
) {
  const rules = cloneDeep(validationRules) || {};
  rules.validate = rules.validate || {};

  if (rules?.required === true) {
    rules.required = t('site.purchase.validation.required');
  }

  if (rules?.year) {
    rules.validate.year = (value) =>
      value.length === 4 || t('site.purchase.validation.year');
  }

  if (rules?.email) {
    rules.validate.email = (value) => {
      const errorMessage = isBoolean(rules.email)
        ? t('site.purchase.validation.email')
        : rules.email;
      return !value || Patterns.isEmail(value) || errorMessage;
    };
  }

  if (rules?.telephone) {
    rules.validate.telephone = (value) => {
      const errorMessage = isBoolean(rules.telephone)
        ? t('site.purchase.validation.telephone')
        : rules.telephone;
      return !value || Patterns.isTelephoneNumber(value) || errorMessage;
    };
  }

  if (rules?.mobile) {
    rules.validate.mobile = (value) => {
      const errorMessage = isBoolean(rules.mobile)
        ? t('site.purchase.validation.telephone')
        : rules.mobile;
      return !value || Patterns.isMobileNumber(value) || errorMessage;
    };
  }

  if (rules?.postcode) {
    rules.validate.postcode = (value) => {
      const errorMessage = isBoolean(rules.postcode)
        ? t('site.purchase.validation.postcode')
        : rules.postcode;
      return !value || Patterns.isPostcode(value) || errorMessage;
    };
  }

  if (rules?.match?.value) {
    rules.validate.match = (value) =>
      value === getValues(rules.match.value) || rules.match.message || false;
  }

  if (rules?.atLeastOneOption) {
    rules.validate.atLeastOneOption = () => {
      const errorMessage = isBoolean(rules.atLeastOneOption)
        ? t('site.purchase.validation.at-least-one-option')
        : rules.atLeastOneOption;
      const isValid =
        Object.values(get(getValues(), name)).filter(
          (value) => value === 'true' || value === true,
        ).length > 0;

      return isValid || errorMessage;
    };
  }

  if (rules?.atLeastOneFile) {
    rules.validate.atLeastOneFile = () => {
      const errorMessage = isBoolean(rules.atLeastOneFile)
        ? t('site.purchase.validation.required')
        : rules.atLeastOneFile;
      const value = get(getValues(), name);
      const isValid = value && value.length > 0;

      return isValid || errorMessage;
    };
  }

  if (rules?.maxFileSize?.value) {
    const maxFileSize = rules.maxFileSize.value * 1024;

    rules.validate.maxFileSize = () => {
      const defaultErrorMessage = t('site.purchase.validation.file.max-size', {
        0: returnFileSize(maxFileSize, 0),
      });
      const isValid =
        Array.from(get(getValues(), name) || []).filter(
          (file) => file.size > maxFileSize,
        ).length === 0;

      return isValid || rules.maxFileSize.message || defaultErrorMessage;
    };
  }

  return rules;
}

export function returnFileSize(number, fixed = 2) {
  if (number < 1024) {
    return `${number} bytes`;
  } else if (number > 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(fixed)} KB`;
  } else {
    return `${(number / 1048576).toFixed(fixed)} MB`;
  }
}

export function getFileNameFromPath(path) {
  return path
    .split('/')
    .pop()
    .replace(/-[0-9]+\./, '.');
}

export function fieldNameHasSpecialCharacters(key) {
  return (
    key.indexOf('__dot__') > -1 ||
    key.indexOf('__cma__') > -1 ||
    key.indexOf('__apos__') > -1
  );
}

export function replaceFieldNameSpecialCharacters(key) {
  return key
    .replace(/__dot__/g, '.')
    .replace(/__cma__/g, ',')
    .replace(/__apos__/g, "'");
}

export const acceptedFileTypes = [
  '.JPEG',
  '.PNG',
  '.GIF',
  '.PDF',
  '.RTF',
  '.DOCX',
  '.XLSX',
  '.PPTX',
];
