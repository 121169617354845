import React from 'react';
import styled from 'styled-components';
import { color } from '../../styles/informatech/colours/colours';
import { typography } from '../../styles/informatech/typography/typography';
import { breakpoint } from '../../styles/shared';

const Logo = styled.img`
  width: auto;
  height: 54px;
  margin-top: 35px;
  margin-bottom: 35px;
  align-self: flex-start;

  @media (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    margin-top: 40px;
    margin-bottom: 60px;
  }
`;

const Prefix = styled.span`
  font-family: ${typography.type.primary};
  font-size: ${typography.size.s1}px;
  color: ${color.lightest};
  display: inline-block;
  position: absolute;
  top: 30px;
  left: 71px;

  @media (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    top: 40px;
  }
`;

const LogoWrap = styled.a`
  position: relative;
  display: inline-block;
`;

export const InformaTechLogo = ({ showPrefix }) => {
  return (
    <LogoWrap href="https://informaconnect.com/">
      {showPrefix ? <Prefix>Brought to you by</Prefix> : null}
      <Logo
        src="/images/btyb_informa_connect_logo_white.png"
        alt="Informa Connect"
      />
    </LogoWrap>
  );
};
