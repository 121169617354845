export enum HubNavBarItemTypes {
  BasePage = 'predefined-hub-nav-bar-item',
  LandingPage = 'landing-page-hub-nav-bar-item',
  CustomLink = 'custom-link-hub-nav-bar-item',
  DropdownGroup = 'group-hub-nav-bar-item',
}

export enum HubPageTypes {
  Home = 'home',
  Base = 'base',
  Landing = 'landing',
}

export enum HubModuleTypes {
  Accordion = 'hub-accordion',
  AlertBanner = 'hub-alert',
  Articles = 'hub-articles',
  Carousel = 'hub-carousel',
  ColumnText = 'hub-custom-text-section',
  FeaturedColumnText = 'hub-featured-text',
  Courses = 'hub-courses',
  EmailOptIn = 'hub-email-opt-in',
  Events = 'hub-events',
  FileDownload = 'hub-file-download',
  Hero = 'hub-hero',
  Iframe = 'hub-iframe',
  Image = 'hub-image',
  RelatedSites = 'hub-related-events',
  Sectors = 'hub-sectors',
  SponsorCategory = 'hub-sponsor-category',
  Speakers = 'hub-promoted-speakers',
  Statistics = 'hub-statistics',
  Testimonials = 'hub-testimonial',
  Video = 'hub-video',
  RichText = 'hub-rich-text',
  StreamlyVideo = 'hub-streamly-video',
  HtmlBlock = 'hub-html-block',
}

export enum HubProductFilterPosition {
  NONE = 'NONE',
  LEFT = 'LEFT',
  TOP = 'TOP',
}

export enum SiteModuleTypes {
  EventSpexCategorySection = 'event_sponsor_category_section',
  CourseOptionDisplaySection = 'course_option_display_section',
  CourseOptionDisplayHeroSection = 'course_option_display_hero_section',
}

export enum HubVisualStyles {
  Dark = 'DARK',
  Light = 'LIGHT',
  LightNoBorder = 'LIGHT_NO_BORDER',
  BrandDark = 'BRAND_DARK',
  BrandLight = 'BRAND_LIGHT',
}

export enum HubModuleThemes {
  Dark = 'hub-module-dark-theme',
  Light = 'hub-module-light-theme',
  BrandDark = 'hub-module-brand-dark-theme',
  BrandLight = 'hub-module-brand-light-theme',
}

export enum DiscoveryContentTypes {
  ALL = 'all',
  EVENT = 'EVENT',
  COURSE = 'COURSE',
  ARTICLE = 'ARTICLE',
  STREAMLY_VIDEO = 'STREAMLY_VIDEO',
  AUDIENCE_HOME = 'AUDIENCE_HOME',
  SPEAKER = 'SPEAKER',
  AGENDA_SESSION = 'AGENDA_SESSION',
  MODULE = 'MODULE',
  SPONSOR = 'SPONSOR',
  EXTERNAL_SITE = 'EXTERNAL_SITE',
}

export enum DeliveryType {
  ON_DEMAND = 'ON_DEMAND',
  FLEXI = 'FLEXI',

  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL',

  CLASSROOM = 'CLASSROOM',
  DISTANCE = 'DISTANCE',
  ONLINE = 'ONLINE',
  IN_PERSON = 'IN_PERSON',
  LIVE_ONLINE = 'LIVE_ONLINE',
  ONLINE_PROGRAMME = 'ONLINE_PROGRAMME',
}

export enum Languages {
  ARABIC = 'ARABIC',
  ENGLISH = 'ENGLISH',
  FRENCH = 'FRENCH',
  GERMAN = 'GERMAN',
  PORTUGUESE = 'PORTUGUESE',
  SPANISH = 'SPANISH',
  VIETNAMESE = 'VIETNAMESE',
}

export const X_INFORMA_USER_ACCESS_TOKEN = 'X-Informa-User-Access-Token';
export const X_INFORMA_TENANT_ID = 'X-Informa-Tenant-Id';

export enum ThemeNames {
  Default = 'Default',
  FanExpoHQ = 'FanExpoHQ',
  KNect365 = 'KNect365',
  AsiaTechXSG = 'AsiaTechXSG',
  OneOfAKind = 'OneOfAKind',
  InteriorDesign = 'InteriorDesign',
  InteriorDesignShow = 'InteriorDesignShow',
  ArtToronto = 'ArtToronto',
  TheArtistProject = 'TheArtistProject',
  MEFCC = 'MEFCC',
}

export const DAY_MONTH = 'DAY_MONTH';
export const MONTH_DAY = 'MONTH_DAY';
export const FIRSTNAME_LASTNAME = 'FIRSTNAME_LASTNAME';
export const FORENAME_SURNAME = 'FORENAME_SURNAME';
