import { PricingPackagesFormValues } from '../../../store/features/packages/types';
import { getProductIdsFromPackageFormValues } from '../getProductIdsFromPackageFormValues';

export interface GetFetchPricingProductsParamsProps {
  basketId: string;
  formValues: PricingPackagesFormValues;
  vipCodeForValidation?: string;
  bundledProductsMap?: Record<string, string[]>;
}

export function getFetchPricingProductsParams(
  props: GetFetchPricingProductsParamsProps,
) {
  const {
    basketId,
    formValues: {
      siteOptionIndex,
      vipCode,
      productsSelections,
      ...restFormValues
    },
    vipCodeForValidation,
    bundledProductsMap,
  } = props;
  const vipCodeValue = vipCodeForValidation || vipCode;

  return {
    basketId,
    courseOptionSubPath: siteOptionIndex,
    queryParams: {
      productIds: getProductIdsFromPackageFormValues(
        productsSelections,
        bundledProductsMap,
      ),
      vipCode: vipCodeValue ? [vipCodeValue] : undefined,
      ...restFormValues,
    },
  };
}
