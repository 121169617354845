import merge from 'lodash/merge';
import { createSelector } from 'reselect';
import { siteTypesEnum } from '../../helpers';
import { getHeaderMAHeader } from '../../utils/getMAHeader';
import { fetchSiteHeaderSuccess } from '../siteDucks/siteHeader';
import { fetchSiteFooterSuccess } from '../siteDucks/siteFooter';

const SUCCESS = 'OPTIONS_SUCCESS';
const FAILURE = 'OPTIONS_FAILURE';

const initialState = {
  loaded: false,
  data: {},
  meta: {},
};

export default function optionsReducer(state = initialState, action) {
  switch (action.type) {
    case SUCCESS:
      return Object.assign(
        {},
        {
          loaded: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    case FAILURE:
      return Object.assign(
        {},
        {
          error: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    default:
      return state;
  }
}

export function fetchOptionsSuccess(data, options) {
  if (options) {
    data.json.data = merge(data.json.data, options);
  }

  return { type: SUCCESS, data };
}

export function fetchOptionsFailure(data) {
  return { type: FAILURE, data };
}

export function fetchPortfolioOptionsAction() {
  return function (dispatch, getState, { Api, i18n }) {
    const {
      pageConfig: { isPreview, portfolioId },
    } = getState();

    return Api()
      .service('ContentService')
      .version('v1')
      .setup({
        headers: {
          'Accept-Language': i18n.language,
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .fetchPortfolioOptions({ portfolioId })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchOptionsSuccess(response));
        } else {
          return dispatch(fetchOptionsFailure(response));
        }
      });
  };
}

export function fetchArticleOptionsAction() {
  return function (dispatch, getState, { Api, i18n }) {
    const {
      pageConfig: { isPreview, siteId, sitePath, tenantId },
    } = getState();

    return Api()
      .service('ContentService')
      .version('v1')
      .setup({
        headers: {
          'Accept-Language': i18n.language,
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .fetchArticleOptions({ siteId, sitePath, tenantId })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchOptionsSuccess(response));
        } else {
          return dispatch(fetchOptionsFailure(response));
        }
      });
  };
}

export function fetchSiteOptionsAction(lng, maConfig) {
  return function (dispatch, getState, { Api }) {
    const {
      pageConfig: {
        isPreview,
        siteId,
        sitePath,
        siteTypePath,
        tenantId,
        portfolioId,
      },
    } = getState();

    const headers = {
      headers: {
        'Published-State': isPreview ? 'Draft' : 'Published',
      },
    };

    if (lng) {
      headers.headers['Accept-Language'] = lng;
    }

    return Api()
      .cache(true, function (RedisClient, response) {
        const {
          data: { relatedCacheScheme: { channelName, binding } = {} } = {},
        } = response;

        if (channelName && binding) {
          RedisClient.updateCacheBinding(channelName, binding);
        }
      })
      .service('ContentService')
      .version('v1')
      .setup(headers)
      .fetchSiteOptions({
        siteId,
        sitePath,
        siteTypePath,
        domain: portfolioId || '',
        tenantId,
      })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchOptionsSuccess(response, maConfig));
        } else {
          return dispatch(fetchOptionsFailure(response));
        }
      });
  };
}

export function fetchSiteLayoutAction() {
  return function (dispatch, getState, { Api, i18n, req: request }) {
    const {
      pageConfig: {
        isPreview,
        siteId,
        sitePath,
        siteTypePath,
        tenantId,
        portfolioId,
      },
    } = getState();

    const maConfig = {
      'x-ma-segments': request?.header?.('x-ma-segments'),
    };

    return Api()
      .cache(true, function (RedisClient, response) {
        const {
          data: { relatedCacheScheme: { binding, channelName } = {} } = {},
        } = response;

        if (binding && channelName) {
          RedisClient.updateCacheBinding(channelName, binding);
        }
      })
      .service('ContentService')
      .version('v1')
      .setup((req) => {
        return {
          headers: {
            'Accept-Language': i18n.language,
            'Published-State': isPreview ? 'Draft' : 'Published',
            ...getHeaderMAHeader(req, maConfig),
          },
        };
      })
      .fetchSiteLayout({
        siteId,
        sitePath,
        siteTypePath,
        domain: portfolioId || '',
        tenantId,
      })
      .then((response) => {
        if (response.statusCode === 200) {
          dispatch(
            fetchSiteHeaderSuccess({
              json: response.json.header,
              statusCode: response.statusCode,
            }),
          );
          dispatch(
            fetchSiteFooterSuccess({
              json: response.json.footer,
              statusCode: response.statusCode,
            }),
          );
          dispatch(
            fetchOptionsSuccess(
              {
                json: response.json.options,
                statusCode: response.statusCode,
              },
              maConfig,
            ),
          );
        } else {
          dispatch(fetchOptionsFailure(response));
        }
      });
  };
}

export function fetchIndustryOptionsAction() {
  return function (dispatch, getState, { Api, i18n }) {
    const {
      pageConfig: { isPreview, siteId, siteTypePath },
    } = getState();

    return Api()
      .service('ContentService')
      .version('v1')
      .setup({
        headers: {
          'Accept-Language': i18n.language,
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .fetchIndustryOptions({ siteId, siteTypePath })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchOptionsSuccess(response));
        } else {
          return dispatch(fetchOptionsFailure(response));
        }
      });
  };
}

export function fetchAudienceOptionsAction() {
  return function (dispatch, getState, { Api, i18n }) {
    const {
      pageConfig: { isPreview, siteId, siteTypePath },
    } = getState();

    return Api()
      .service('ContentService')
      .version('v1')
      .setup({
        headers: {
          'Accept-Language': i18n.language,
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .fetchAudienceOptions({ siteId, siteTypePath })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchOptionsSuccess(response));
        } else {
          return dispatch(fetchOptionsFailure(response));
        }
      });
  };
}

export const getIsVirtualDT = createSelector(
  (state) => state.options.data,
  (state) => state.pageConfig.siteType,
  ({ deliveryType, cveDelegateSignupInfo }, siteType) => {
    const { EVENT } = siteTypesEnum();

    return (
      siteType === EVENT && deliveryType === 'VIRTUAL' && cveDelegateSignupInfo
    );
  },
);
