import Cookies from 'js-cookie';
import qs from 'qs';
import jwtDecode from 'jwt-decode';
import clientConfig from '../../clientConfig';
import {
  canCookieBeSecure,
  getTenantIdFromUrl,
  getCookieDomainFromUrl,
  isBrowser,
  isString,
  isAbsoluteUrl,
} from '../../helpers';
import {
  X_INFORMA_USER_ACCESS_TOKEN,
  X_INFORMA_TENANT_ID,
} from '../../constants';

const GIVEN_NAME = 'given_name';
const FAMILY_NAME = 'family_name';
const NEW_ACCOUNT = 'custom:new_account';
const CUSTOM_EMAIL = 'custom:custom_email';

const service = {
  login({ username, password }) {
    return this._makeRequest({
      method: 'POST',
      url: '/iasapi/login',
      body: {
        username,
        password,
      },
    });
  },

  logout() {
    return this._makeRequest({
      method: 'GET',
      url: '/iasapi/logout',
    })
      .then((response) => response.json())
      .then(() => {
        this.removeToken();
        window.location.reload();
      });
  },

  createAccount(data, username, password, tenantId) {
    const { forename, surname } = data;

    return this._makeRequest({
      method: 'POST',
      url: '/iasapi/signup',
      body: {
        username,
        password,
        userAttributes: [
          {
            name: CUSTOM_EMAIL,
            value:
              tenantId === 'KNECT365' ? 'default' : tenantId?.toLowerCase(),
          },
          {
            name: GIVEN_NAME,
            value: forename,
          },
          {
            name: FAMILY_NAME,
            value: surname,
          },
        ],
        profile: {
          core: {
            ...data,
          },
          p1: {},
          responses: {
            agenda: {},
            content: {},
            vertical: {},
            sponsor: {},
            eventPartners: {},
          },
        },
      },
    });
  },

  getAccountDetails({ p1 = false } = {}) {
    const shortAccountDetails = this.getShortAccountDetails();

    if (!shortAccountDetails.loggedIn) {
      return Promise.resolve({});
    }

    const token = this.getToken();
    const username = shortAccountDetails['cognito:username'];
    const subProfiles = p1 ? 'p1' : 'core';
    const tenantId = this._getTenantId();

    return this._makeRequest({
      method: 'GET',
      url: `/iasapi/profile/${username}?tenantId=${tenantId}&subProfiles=${subProfiles}`,
      headers: { [X_INFORMA_USER_ACCESS_TOKEN]: token },
    });
  },

  fetchAccountDetails(subProfiles = 'core') {
    const { 'cognito:username': username } = this.getShortAccountDetails();

    return this._makeRequest({
      method: 'GET',
      url: `/iasapi/profile/${username}?subProfiles=${subProfiles}`,
      headers: {
        [X_INFORMA_USER_ACCESS_TOKEN]: this.getToken(),
      },
    });
  },

  fetchGuestDetails(guestId) {
    return this._makeRequest({
      method: 'GET',
      url: `/iasapi/guests/${guestId}`,
    });
  },

  updateAccountDetails(body) {
    const token = this.getToken();
    const shortAccountDetails = this.getShortAccountDetails();
    const username = shortAccountDetails['cognito:username'];
    const tenantId = this._getTenantId();

    return this._makeRequest({
      method: 'POST',
      url: `/iasapi/profile/core/${username}?tenantId=${tenantId}`,
      body,
      headers: { [X_INFORMA_USER_ACCESS_TOKEN]: token },
    });
  },

  uploadProfilePhoto(file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name);
    formData.append('imageType', file.type);
    formData.append('bucketName', 'accountBucketName');

    return fetch('/upload', {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, *!/!*',
      },
      body: formData,
    });
  },

  sendForgotPassword({ username, tenantId }) {
    return this._makeRequest({
      method: 'POST',
      url: '/iasapi/forgot',
      body: {
        username,
        userAttributes: [
          {
            name: CUSTOM_EMAIL,
            value:
              tenantId === 'KNECT365' ? 'default' : tenantId?.toLowerCase(),
          },
          {
            name: NEW_ACCOUNT,
            value: false,
          },
        ],
      },
    });
  },

  sendDelegateForgotPassword(data) {
    const { username, sitePath, siteUrl, logo, template } = data;

    return this._makeRequest({
      method: 'POST',
      url: '/iasapi/forgot',
      body: {
        username,
        userAttributes: [
          {
            name: CUSTOM_EMAIL,
            value: template || 'default',
          },
          {
            name: 'custom:event_name',
            value: sitePath,
          },
          {
            name: 'custom:event_url',
            value: siteUrl,
          },
          {
            name: 'custom:event_logo',
            value: logo,
          },
          {
            name: NEW_ACCOUNT,
            value: false,
          },
        ],
      },
    });
  },

  sendDelegateResetPassword({ username, password, confirmationCode }) {
    return this._makeRequest({
      method: 'POST',
      url: '/iasapi/reset',
      body: {
        username,
        password,
        confirmationCode,
      },
    });
  },

  checkEventAttendance(productCodes = []) {
    const shortAccountDetails = this.getShortAccountDetails();
    const username = shortAccountDetails['cognito:username'];
    const token = this.getToken();

    const queryString = qs.stringify(
      {
        tenantId: this._getTenantId(),
        code: productCodes,
      },
      {
        encode: true,
        indices: false,
      },
    );

    return this._makeRequest({
      method: 'GET',
      url: `/iasapi/profile/${username}/event-attendance/contains-any?${queryString}`,
      headers: { [X_INFORMA_USER_ACCESS_TOKEN]: token },
    });
  },

  resendConfirmationEmail({ username }) {
    return this._makeRequest({
      method: 'GET',
      url: `/iasapi/resend?username=${username}`,
    });
  },

  validateToken() {
    return this._makeRequest({
      method: 'GET',
      url: '/iasapi/token',
    });
  },

  /* istanbul ignore next */
  getShortAccountDetails() {
    const accessToken = this.getToken();

    if (!accessToken) {
      return {
        loggedIn: false,
      };
    }

    const account = jwtDecode(accessToken);

    return {
      loggedIn: true,
      email: account.email,
      forename: account.given_name || '',
      surname: account.family_name || '',
      get fullName() {
        if (this.loggedIn) {
          return [this.forename, this.surname].filter(Boolean).join(' ');
        }

        return '';
      },
      'cognito:username': account['cognito:username'],
    };
  },

  /* istanbul ignore next */
  isLoggedIn() {
    return !!this.getToken();
  },

  /* istanbul ignore next */
  getToken() {
    return Cookies.get('access_token', {
      path: '/',
      domain: this._getCookieDomain(),
      secure: canCookieBeSecure(),
    });
  },

  /* istanbul ignore next */
  setToken({ token, expiresIn } = {}) {
    if (token) {
      Cookies.set('access_token', token, {
        expires: new Date(Date.now() + expiresIn * 1000),
        path: '/',
        domain: this._getCookieDomain(),
        secure: canCookieBeSecure(),
      });
    }
  },

  /* istanbul ignore next */
  removeToken() {
    Cookies.remove('access_token', {
      path: '/',
      domain: this._getCookieDomain(),
      secure: canCookieBeSecure(),
    });
  },

  _makeRequest({ method = 'GET', url, body, headers = {} }) {
    const requestUrl = isAbsoluteUrl(url) ? url : this._getHost() + url;

    return fetch(requestUrl, {
      method,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        [X_INFORMA_TENANT_ID]: this._getTenantId(),
        ...headers,
      },
      body: body ? (isString(body) ? body : JSON.stringify(body)) : undefined,
    });
  },

  _getHost() {
    return '';
  },

  _getTenantId() {
    return clientConfig.api.services.AAAService.tenant?.id;
  },

  _getCookieDomain() {
    if (isBrowser()) {
      return getCookieDomainFromUrl(document.location.hostname);
    }

    return null;
  },
};

export default service;
