import { siteTypesEnum } from './enums';
import { RouteInstance } from '../types';

const {
  HUB,
  STATIC,
  BRAND_HUB,
  AUDIENCE_HUB,
  PORTFOLIO,
  ARTICLE,
  EVENT,
  COURSE,
  L1_TOPIC_HUB,
  L2_TOPIC_HUB,
} = siteTypesEnum();

export default function findActiveRoutesGroup(
  siteType: keyof ReturnType<typeof siteTypesEnum>,
  audienceType: string,
  routes: Record<string, RouteInstance[]>,
  newPaymentJourneyEnabled?: boolean,
) {
  let routesGroup: RouteInstance[];

  switch (siteType) {
    case HUB:
      routesGroup = routes.knect365Routes;
      break;

    case PORTFOLIO:
      routesGroup = routes.portfolioRoutes;
      break;

    case STATIC:
      routesGroup = routes.staticRoutes;
      break;

    case BRAND_HUB:
      routesGroup = routes.brandHubRoutes;
      break;

    case AUDIENCE_HUB:
      routesGroup = routes.audienceHubRoutes;
      break;

    case ARTICLE:
      if (audienceType === AUDIENCE_HUB) {
        routesGroup = routes.articleRoutes;
      } else {
        routesGroup = routes.legacyArticleRoutes;
      }
      break;

    case EVENT:
    case COURSE: {
      const siteRoutes = [...routes.siteRoutes];
      const paymentRoutes = newPaymentJourneyEnabled
        ? routes.paymentRoutes
        : routes.legacyPaymentRoutes;

      // Paste payment routes before the last two site landing pages routes element
      siteRoutes.splice(-2, 0, ...paymentRoutes);

      routesGroup = siteRoutes;
      break;
    }

    case L1_TOPIC_HUB:
      routesGroup = routes.industryRoutes;
      break;

    case L2_TOPIC_HUB:
      routesGroup = routes.audienceRoutes;
      break;

    default:
      routesGroup = [];
  }

  return routesGroup;
}
