const X_MA_SEGMENTS = 'x-ma-segments';

export function getContentMAHeader(req, maConfig = {}, maSegments = {}) {
  const maHeader = {};

  const requestSegments = getRequestSegments(req, maSegments);
  const cookieSegments = req?.universalCookies?.get(X_MA_SEGMENTS);

  if (requestSegments || cookieSegments) {
    // Preview mode
    maHeader[X_MA_SEGMENTS] = requestSegments ?? cookieSegments;
  }
  return maHeader;
}

export function getHeaderMAHeader(req, maConfig = {}, maSegments = {}) {
  const maHeader = {};

  const requestSegments = getRequestSegments(req, maSegments);
  const cookieSegments = req?.universalCookies?.get(X_MA_SEGMENTS);

  if (requestSegments || cookieSegments) {
    // Preview mode
    maHeader[X_MA_SEGMENTS] = requestSegments ?? cookieSegments;
  }
  return maHeader;
}

function getRequestSegments(req, maSegments) {
  if (maSegments[X_MA_SEGMENTS]) {
    return maSegments[X_MA_SEGMENTS];
  }

  if (req && typeof req.get === 'function' && req.get(X_MA_SEGMENTS)) {
    return req.get(X_MA_SEGMENTS);
  }
}
