import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultPackageFormValues } from '../../../helpers/selectPackage/getPreselectedPackageFormValues';
import { PricingPackagesFormValues } from '../packages/types';
import { RootState } from '../../../../../store/store';

const initialState = {
  data: defaultPackageFormValues,
};

const packageFormDefaultValuesSlice = createSlice({
  name: 'packageFormDefaultValues',
  initialState,
  reducers: {
    setPackageFormDefaultValues: (
      state,
      action: PayloadAction<PricingPackagesFormValues>,
    ) => {
      state.data = action.payload;
    },
  },
});

export const selectPackageFormDefaultValuesData = (state: RootState) =>
  state.pricing.packageFormDefaultValues.data;

export const { setPackageFormDefaultValues } =
  packageFormDefaultValuesSlice.actions;

export default packageFormDefaultValuesSlice.reducer;
