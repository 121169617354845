import { HTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import style from './Heading.module.scss';
import {
  PropsWithTestId,
  TitleSizeType,
  HeadingSizeTags,
} from '../../../../../../types';

export interface HeadingProps
  extends PropsWithTestId<PropsWithChildren<HTMLAttributes<HTMLElement>>> {
  titleSize?: TitleSizeType;
}

export const Heading = (props: HeadingProps) => {
  const { className, children, testId, titleSize = 'H2', ...restProps } = props;
  const HeadingTag = HeadingSizeTags[titleSize];

  return (
    <HeadingTag
      {...restProps}
      data-testid={testId}
      className={cn(style.header, className)}
    >
      {children}
    </HeadingTag>
  );
};

Heading.defaultProps = {
  testId: 'hub-module-heading',
};
