import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Cookies, withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { Field, getFormValues, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import Scroll from 'react-scroll';
// TODO: remove dot-object lib, it is used only here
import DotObject from 'dot-object';
import jwtDecode from 'jwt-decode';

import { X_INFORMA_USER_ACCESS_TOKEN } from '../../../constants';
import leadGenApi from '../../../api/LeadGenService/v1';
import { stringifyQueryParams } from '../../../helpers';
import Auth from '../../../services/AuthService';
import gtm from '../../../utils/initialGTM';
import { fetchLeadGenFormAction } from '../../../store/commonDucks/leadGenForm';
import { fetchSitePartnersAction } from '../../../store/siteDucks/sitePartners';

import LeadGenProfile from './LeadGenProfile/LeadGenProfile';
import LeadGenAdHocQuestions from './LeadGenAdHocQuestions/LeadGenAdHocQuestions';
import LeadGenEmailAndPass from './LeadGenEmailAndPass/LeadGenEmailAndPass';
import LeadGenConsent from './LeadGenConsent/LeadGenConsent';
import StyledCTA from '../../../informakit/components/StyledCTA';
import LeadGenInput from '../LeadGenFormItems/LeadGenInput/LeadGenInput';
import './LeadGenForm.scss';
import { transformLeadGenProfile } from '../../../utils/transformLeadGenProfile';

export class LeadGenForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    gateId: PropTypes.string.isRequired,
    setSuccessAndUsernameStates: PropTypes.func.isRequired,
    pageUrl: PropTypes.string,
    leadGenType: PropTypes.string,
    selectedCountry: PropTypes.string,
    color: PropTypes.string,
    buttonStyle: PropTypes.object,
    history: PropTypes.object,
    audienceName: PropTypes.string,
    cookies: PropTypes.instanceOf(Cookies),
    fetchLeadGenForm: PropTypes.func,
    fetchSitePartners: PropTypes.func,
    options: PropTypes.shape({
      data: PropTypes.shape({
        productCode: PropTypes.string,
        page: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
    }),
    siteHeader: PropTypes.shape({
      data: PropTypes.shape({
        nameFormat: PropTypes.string,
      }),
    }),
  };

  constructor(props) {
    super(props);
    this.errorMessage = React.createRef();

    this.state = {
      loading: false,
      showCountryStates: false,
      errorMessage: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { formData: { profile = [], type } = {} } = this.props;
    gtm.pushFormType(type);
    // If the input field "addressLine1" is off on admin panel
    // the drop-down field "state" must be always hidden and disabled
    // (even in case USA/Canada is selected)
    const showCountryStates = profile.some((input) => {
      return (
        input.name === 'addressLine1' || input.name === 'profile.addressLine1'
      );
    });

    this.setState({
      showCountryStates,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      fetchLeadGenForm,
      leadGenType,
      pageConfig: { siteId } = {},
      selectedCountry,
      siteContent: { data: { id: pageId } = {} } = {},
    } = this.props;

    const queryParams = {
      selectedCountry,
    };
    const accessToken = Auth.getToken();
    if (accessToken) {
      queryParams.token = this.props.cookies.get('access_token');
      queryParams.username = jwtDecode(accessToken)['cognito:username'];
    }

    if (selectedCountry !== prevProps.selectedCountry) {
      fetchLeadGenForm({
        leadGenType,
        leadGenId: leadGenType === 'landing-page' ? pageId : siteId,
        queryParams,
      });
    }
  }

  async onSubmit(values) {
    if (values.company) return;

    this.setState({ loading: true });
    const {
      formData: {
        profile = [],
        type = '',
        marketingPermissions: { consentForms = [] } = {},
      } = {},
      gateId,
      pageUrl,
      setSuccessAndUsernameStates,
      history,
      leadGenType,
      pageConfig: { tenantId, canonicalUrl } = {},
      options: { data: { productCode = '' } = {} } = {},
    } = this.props;
    const submitFormValues = { ...values };
    const { adHocQAndAModel: { adHocAnswersMap } = {} } = submitFormValues;
    const priorityCode = this.props.cookies.get('tracker_id');
    let queryParams = priorityCode
      ? { priority_code: priorityCode, gate_id: gateId }
      : { gate_id: gateId };
    const isContentInterest = profile.some((item) => item.type === 'HIDDEN');

    if (adHocAnswersMap) {
      const addHocQKeys = Object.keys(adHocAnswersMap);
      addHocQKeys.forEach((item) => {
        // each property of adHocAnswersMap object must be an array of string for sending request
        // transform object property from string to array if it's a string.
        if (typeof adHocAnswersMap[item] === 'string') {
          adHocAnswersMap[item] = [adHocAnswersMap[item]];
        }
      });
    }

    // contentInterest must have productCode in any case for sending request
    if (submitFormValues.contentInterest && productCode) {
      // if a user selects contentInterest we will add additional key productCode with contentInterest value
      submitFormValues.contentInterest.productCode = productCode;
    } else if (isContentInterest && productCode) {
      // if a user doesn't select contentInterest we will send only object with productCode
      submitFormValues.contentInterest = {
        productCode,
      };
    }

    consentForms.length &&
      this.addConsentAnswersToSubmitValues(
        submitFormValues,
        consentForms,
        canonicalUrl,
      );

    if (type === 'UPDATE') {
      // if state is null we will delete state from body before request
      submitFormValues.profile &&
        submitFormValues.profile.state === null &&
        delete submitFormValues.profile.state;
      const token = Auth.getToken();
      const userInfo = jwtDecode(token);
      // add additional query params for update
      queryParams = {
        ...queryParams,
        username: userInfo['cognito:username'],
      };

      return fetch(
        leadGenApi.updateLeadGenUser({
          queryParams: stringifyQueryParams(queryParams),
        }),
        {
          method: 'POST',
          body: JSON.stringify(submitFormValues),
          headers: {
            'Content-Type': 'application/json',
            [X_INFORMA_USER_ACCESS_TOKEN]: token,
          },
        },
      )
        .then((response) => response.json())
        .then(({ errors, message }) => {
          if (errors) {
            throw Object.assign({}, { errors, message });
          }
          leadGenType === 'article' &&
            history.push(history.location.search + '?updated=true');
          this.setState({ loading: false });
          document.location.reload(true);
        })
        .catch(({ errors, message }) => {
          if (message) {
            this.setState({ errorMessage: message, loading: false }, () => {
              Scroll.animateScroll.scrollTo(
                this.errorMessage.current.offsetTop,
              );
            });
          }

          throw new SubmissionError(DotObject.object(errors));
        });
    } else {
      // add additional query params for registration
      queryParams = { ...queryParams, login_url: pageUrl };
      // if state is null we will delete state from body before request
      submitFormValues.state === null && delete submitFormValues.state;

      try {
        const response = await fetch(
          leadGenApi.createLeadGenUser({
            queryParams: stringifyQueryParams(queryParams),
          }),
          {
            method: 'POST',
            body: JSON.stringify(
              Object.assign({}, submitFormValues, {
                userAttributes: [
                  {
                    name: 'custom:custom_email',
                    value:
                      tenantId === 'KNECT365'
                        ? 'default'
                        : tenantId?.toLowerCase(),
                  },
                ],
              }),
            ),
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        const result = await response.json();

        if (!response.ok) {
          throw result;
        }

        if (response.ok && response.status === 200) {
          this.setState({ loading: false });
          setSuccessAndUsernameStates(true, submitFormValues.email);
        }
      } catch (error) {
        const errorMessage = error?.errors?.email || error?.message;

        errorMessage &&
          this.setState({ errorMessage, loading: false }, () => {
            Scroll.animateScroll.scrollTo(this.errorMessage.current.offsetTop);
          });
      }
    }
  }

  getStatesData(selectedCountry, stateOptions) {
    return (
      stateOptions &&
      stateOptions.filter((item) => {
        return item.id === selectedCountry;
      })
    );
  }

  getFormTitle(isRegistrationForm, tenantName, profile) {
    return isRegistrationForm
      ? `Create your ${tenantName} account`
      : this.isContactUpdateRequired(profile)
      ? 'Access to this content requires additional details'
      : '';
  }

  isContactUpdateRequired(profile) {
    return !!profile.some(
      (formElement) =>
        formElement.name === 'profile.telephone' ||
        formElement.name === 'profile.addressLine1',
    );
  }

  getFormTitleCase(leadGenType, type, profile) {
    return leadGenType !== 'article' &&
      type === 'UPDATE' &&
      this.isContactUpdateRequired(profile)
      ? 'lead-gen-form__title--uppercase'
      : '';
  }

  addConsentAnswersToSubmitValues(
    submitFormValues,
    consentForms,
    canonicalUrl,
  ) {
    const userConsentAnswers = [];

    consentForms.forEach(({ id, checkboxes }) => {
      const submitAnswerValue = submitFormValues[id];

      userConsentAnswers.push({
        id,
        answers: checkboxes.map(({ name, checked: defaultAnswerValue }) => {
          return {
            channel: name,
            value:
              (submitAnswerValue && submitAnswerValue[name]) ||
              defaultAnswerValue,
          };
        }),
      });
      delete submitFormValues[id];
    });

    submitFormValues.marketingPreferences = {
      defaultConsents: true,
      url: canonicalUrl,
      userConsentAnswers,
    };
  }

  render() {
    const {
      handleSubmit,
      formData: {
        profile: formProfile = [],
        adHocQuestions = [],
        stateOptions = [],
        type,
        marketingPermissions: { consentForms = [] } = {},
      } = {},
      leadGenType,
      color,
      buttonStyle = {},
      selectedCountry,
      audienceName,
      tenantName,
      pageConfig: { tenantConfig: { outputName } = {} } = {},
      siteHeader,
      fetchSitePartners,
    } = this.props;
    const { loading, showCountryStates, errorMessage } = this.state;
    const isRegistrationForm = type === 'REGISTRATION';

    const nameFormat = siteHeader?.data?.nameFormat;
    const profile =
      leadGenType !== 'article'
        ? transformLeadGenProfile(nameFormat, formProfile)
        : formProfile;

    // styles with type-1 for article pages
    // styles with type-2 for landing and agenda pages
    const articleTypeClass =
      leadGenType === 'article'
        ? 'lead-gen-form__title-type-1'
        : 'lead-gen-form__title-type-2';
    const buttonClass =
      leadGenType === 'article'
        ? 'c-button__lead-gen-type-1'
        : 'c-button__lead-gen-type-2';
    const updateFormClass = isRegistrationForm
      ? ''
      : 'lead-gen-form__title--update-form';
    const leadGenFormClass = isRegistrationForm
      ? 'lead-gen-register-form'
      : 'lead-gen-update-form';
    const leadGenButtonClass = isRegistrationForm
      ? 'lead-gen-register-button'
      : 'lead-gen-update-button';

    return (
      <div
        className={`lead-gen-form ${leadGenFormClass}`}
        data-test-lead-gen="lead-gen-form"
      >
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="row">
            <div className="col-xs-12">
              {isRegistrationForm && (
                <h3
                  className={`lead-gen-form__title ${articleTypeClass} ${updateFormClass} ${this.getFormTitleCase(
                    leadGenType,
                    type,
                    profile,
                  )}`}
                  data-test-lead-gen="lead-gen-form-title"
                >
                  {this.getFormTitle(isRegistrationForm, tenantName, profile)}
                </h3>
              )}
              {errorMessage && (
                <div
                  ref={this.errorMessage}
                  className="lead-gen-form__error-message"
                  data-test-lead-gen="lead-gen-form-error-message"
                >
                  {errorMessage}
                </div>
              )}
            </div>
          </div>
          {profile.length > 0 && (
            <LeadGenProfile
              profileFormData={profile}
              stateOptions={this.getStatesData(selectedCountry, stateOptions)}
              audienceName={audienceName}
              showCountryStates={showCountryStates}
              isUpdate={type === 'UPDATE'}
              data-test-lead-gen="lead-gen-profile"
            />
          )}
          {adHocQuestions.length > 0 && (
            <LeadGenAdHocQuestions
              adHocFormData={adHocQuestions}
              leadGenType={leadGenType}
              data-test-lead-gen="lead-gen-ad-hoc"
            />
          )}
          {isRegistrationForm && (
            <LeadGenEmailAndPass data-test-lead-gen="lead-gen-email-and-pass" />
          )}
          <div hidden>
            <Field type="text" name="company" component={LeadGenInput} />
          </div>
          {consentForms.length > 0 && (
            <LeadGenConsent
              color={color}
              leadGenType={leadGenType}
              consentForms={consentForms}
              fetchSitePartners={fetchSitePartners}
              tenantName={outputName}
              data-test-lead-gen="lead-gen-preferences"
            />
          )}
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <StyledCTA
                elementType="button"
                messaging={isRegistrationForm ? 'Create account' : 'Continue'}
                colours={{
                  activeBG: buttonStyle.backgroundColor,
                  borderColour: buttonStyle.borderColor,
                }}
                styleType={leadGenType === 'article' ? 'curved' : 'block'}
                className={`${leadGenButtonClass} ${buttonClass}`}
                disabled={loading}
                loading={loading}
                data-test-lead-gen="button-lead-gen"
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

let LeadGenFormContainer = reduxForm({
  form: 'leadGenForm',
  onSubmitFail: function (errors = {}) {
    const errorFields = errors.profile ? errors.profile : errors;
    const errorFieldsId = errors && Object.keys(errorFields);
    const topErrorField =
      errorFieldsId &&
      errorFieldsId.length &&
      document.getElementById(errorFieldsId[0]);

    if (topErrorField) {
      Scroll.animateScroll.scrollTo(topErrorField.offsetTop);
    }
  },
})(LeadGenForm);

function mapDispatchToProps(dispatch) {
  return {
    fetchLeadGenForm({ leadGenType, leadGenId, queryParams }, callback) {
      return dispatch(
        fetchLeadGenFormAction(
          { leadGenType, leadGenId, queryParams },
          callback,
        ),
      );
    },
    fetchSitePartners() {
      return dispatch(fetchSitePartnersAction());
    },
  };
}

LeadGenFormContainer = connect((state) => {
  const {
    leadGenForm: {
      data: { form: { profile = [], type } = {}, form } = {},
    } = {},
  } = state;
  const formValues = getFormValues('leadGenForm')(state) || {};
  const { country = '', profile: { country: profileCountry = '' } = {} } =
    formValues;
  const initProfileValues = {};
  const selectedCountry = type === 'UPDATE' ? profileCountry : country;
  // forbidden inputs for generating profile initial values
  const forbiddenInputs = ['CHECKBOX_GROUP', 'CHECKBOX', 'HIDDEN'];

  // we need to delete 'profile.' in initial value names
  // if we don't do it, we can't set initial values in the redux form
  if (type === 'UPDATE') {
    profile.forEach((input) => {
      // we need set initial values only for SELECT and TEXT inputs
      const isForbiddenInput = forbiddenInputs.some(
        (inputType) => inputType === input.type,
      );

      if (input.value && !isForbiddenInput) {
        initProfileValues[input.name.replace(/profile./gi, '')] = input.value;
      }
    });
  }

  if (type === 'UPDATE' && !!Object.keys(initProfileValues).length) {
    return {
      options: state.options,
      pageConfig: state.pageConfig,
      siteContent: state.siteContent,
      siteHeader: state.siteHeader,
      selectedCountry,
      initialValues: {
        profile: {
          ...initProfileValues,
        },
      },
      formData: form,
    };
  }

  return {
    selectedCountry,
    formData: form,
    options: state.options,
    siteHeader: state.siteHeader,
    pageConfig: state.pageConfig,
    siteContent: state.siteContent,
  };
}, mapDispatchToProps)(LeadGenFormContainer);

export default withRouter(withCookies(LeadGenFormContainer));
