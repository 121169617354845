export const SUCCESS = 'MA_SEGMENTS_SUCCESS';
export const FAILURE = 'MA_SEGMENTS_FAILURE';

const initialState = {
  loaded: false,
};

export function chosenSegmentsSuccess(data) {
  return { type: SUCCESS, data };
}

export function chosenSegmentsFailure(data) {
  return { type: FAILURE, data };
}

export default function chosenSegmentsReducer(state = initialState, action) {
  switch (action.type) {
    case SUCCESS:
      return chosenSegmentsSuccess(state, action);

    case FAILURE:
      return Object.assign(
        {},
        {
          error: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    default:
      return state;
  }
}
