import { getNameFormatTranslation } from '../../helpers';
import { FIRSTNAME_LASTNAME, FORENAME_SURNAME } from '../../constants';

export function transformLeadGenProfile(
  nameFormat: typeof FIRSTNAME_LASTNAME | typeof FORENAME_SURNAME,
  profileFields: Array<{ name: string }>,
) {
  return profileFields.map((profileInput) => {
    if (profileInput.name === 'forename') {
      return {
        ...profileInput,
        label: getNameFormatTranslation(nameFormat, 'firstname'),
      };
    }
    if (profileInput.name === 'surname') {
      return {
        ...profileInput,
        label: getNameFormatTranslation(nameFormat, 'lastname'),
      };
    }
    return profileInput;
  });
}
