import { createSelector } from 'reselect';
import shuffle from 'lodash/shuffle';
import { getContentMAHeader } from '../../utils/getMAHeader';

const REQUEST = 'SITE_CONTENT_REQUEST';
const SUCCESS = 'SITE_CONTENT_SUCCESS';
const FAILURE = 'SITE_CONTENT_FAILURE';

const initialState = {
  loaded: false,
  data: {},
};

export default function siteContentReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case SUCCESS: {
      const data = Object.assign(
        {},
        {
          loaded: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );
      data.data.sections = shuffleSiteBuilderModules(data.data.sections);

      return data;
    }

    case FAILURE:
      return Object.assign(
        {},
        {
          error: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    default:
      return state;
  }
}

export function fetchSiteContentRequest(data) {
  return { type: REQUEST, data };
}

export function fetchSiteContentSuccess(data) {
  return { type: SUCCESS, data };
}

export function fetchSiteContentFailure(data) {
  return { type: FAILURE, data };
}

export function fetchSiteContentAction(props) {
  const {
    landingPath,
    landingGroupPath,
    sitePage,
    maSegments,
    visualEditorPreviewConfig,
  } = props;

  return function (dispatch, getState, { Api, i18n }) {
    dispatch(fetchSiteContentRequest());

    const {
      pageConfig: { isPreview, siteId, siteTypePath } = {},
      options: { data: { maConfig } = {} } = {},
    } = getState();

    return Api()
      .cache(true)
      .service('ContentService')
      .version('v1')
      .setup((req) => {
        const request = {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': i18n.language,
            'Published-State': isPreview ? 'Draft' : 'Published',
            ...getContentMAHeader(req, maConfig, maSegments),
          },
        };

        if (isPreview) {
          const { visualEditorPreview, visualEditorMode, sectionId } =
            req?.query || {};

          if (visualEditorPreviewConfig) {
            request.method = 'PATCH';
            request.body = JSON.stringify(visualEditorPreviewConfig);
          } else if (visualEditorPreview) {
            request.method = 'PATCH';
            request.body = JSON.stringify({
              sectionId,
              singleSectionView: visualEditorMode === 'MODULE',
            });
          }
        }

        return request;
      })
      .fetchSiteContent({
        siteId,
        siteTypePath,
        sitePage,
        landingPath,
        landingGroupPath,
      })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchSiteContentSuccess(response));
        } else {
          return dispatch(fetchSiteContentFailure(response));
        }
      });
  };
}

export const getLastGrowTixCategoryId = createSelector(
  [(state) => state.siteContent.data.sections],
  (sections = []) => {
    const growTixCategoryId = sections.reduce((identifiers, section) => {
      if (section.type === 'growtix_category_section') {
        identifiers.push(section.categoryId);
      }

      return identifiers;
    }, []);

    return growTixCategoryId[growTixCategoryId.length - 1];
  },
);

export function shuffleSiteBuilderModules(sections = []) {
  return sections.map((section) => {
    switch (section.type) {
      case 'event_testimonial_section': {
        if (section.displayOrder === 'RANDOM') {
          return {
            ...section,
            testimonials: shuffle(section.testimonials),
          };
        }
        return section;
      }

      case 'event_promoted_speakers_section': {
        if (section.displayOrder === 'RANDOM') {
          return {
            ...section,
            promotedSpeakers: shuffle(section.promotedSpeakers),
          };
        }
        return section;
      }

      default:
        return section;
    }
  });
}
