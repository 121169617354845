import { filterValidStreamsAsQueryString } from '../../utils/filterValidStreams';

const v1 = {
  fetchSiteType({
    tenantId,
    sitePath,
    singleSiteMode,
    query = '',
    siteMaskingPath = '',
  }) {
    if (singleSiteMode || sitePath === '/') {
      return siteMaskingPath
        ? `/api/v1/tenant/${tenantId}/sites/${siteMaskingPath}${query}`
        : `/api/v1/tenant/${tenantId}/site${query}`;
    }

    return `/api/v1/tenant/${tenantId}/sites/${sitePath}${query}`;
  },

  fetchPortfolioOptions({ portfolioId }) {
    return `/api/v1/portfolios/${portfolioId}/options?domain=${portfolioId}`;
  },
  fetchArticleOptions({ siteId, sitePath, tenantId }) {
    return `/api/v1/articles/${siteId}/options?contentPath=${sitePath}&domain=${tenantId}`;
  },
  fetchSiteOptions({ siteId, sitePath, siteTypePath, domain, tenantId }) {
    return `/api/v1/${siteTypePath}/${siteId}/options?contentPath=${sitePath}&domain=${domain}&tenant=${tenantId}`;
  },
  fetchSiteLayout({ siteId, sitePath, siteTypePath, domain, tenantId }) {
    return `/api/v1/${siteTypePath}/${siteId}/layout?contentPath=${sitePath}&domain=${domain}&tenant=${tenantId}`;
  },
  fetchIndustryOptions({ siteId, siteTypePath }) {
    return `/api/v1/${siteTypePath}/${siteId}/options`;
  },
  fetchAudienceOptions({ siteId, siteTypePath }) {
    return `/api/v1/${siteTypePath}/${siteId}/options`;
  },

  fetchHubMenu({ tenantId }) {
    return `/api/v1/hubs/${tenantId}/menu`;
  },

  fetchHub({ tenantId }) {
    return `/api/v1/hubs/${tenantId}`;
  },
  fetchPortfolio({ portfolioId }) {
    return `/api/v1/portfolios/${portfolioId}`;
  },

  fetchIndustryHub({ siteId }) {
    return `/api/v1/l1hubs/${siteId}`;
  },
  fetchIndustryHeader({ siteId }) {
    return `/api/v1/l1hubs/${siteId}/header`;
  },

  fetchAudienceHub({ siteId }) {
    return `/api/v1/l2hubs/${siteId}`;
  },
  fetchAudienceHeader({ siteId }) {
    return `/api/v1/l2hubs/${siteId}/header`;
  },
  fetchAudienceEvents({ siteId, query = '' }) {
    return `/api/v1/l2hubs/${siteId}/events${query}`;
  },
  fetchAudienceCourses({ siteId, query = '' }) {
    return `/api/v1/l2hubs/${siteId}/courses${query}`;
  },
  fetchAudienceArticles({ siteId, query = '' }) {
    return `/api/v1/l2hubs/${siteId}/articles${query}`;
  },

  fetchArticleHome({ siteId }) {
    return `/api/v1/articles/${siteId}/home`;
  },

  fetchSiteHeader({ apiHost, siteId, siteTypePath }) {
    if (apiHost) {
      return `${apiHost}/api/v1/${siteTypePath}/${siteId}/header`;
    } else {
      return `/api/v1/${siteTypePath}/${siteId}/header`;
    }
  },
  fetchSiteFooter({ siteId, siteTypePath }) {
    return `/api/v1/${siteTypePath}/${siteId}/footer`;
  },
  fetchSiteContent({
    siteId,
    siteTypePath,
    sitePage,
    landingPath,
    landingGroupPath,
  }) {
    if (landingGroupPath) {
      return `/api/v1/${siteTypePath}/${siteId}/landings/${landingGroupPath}/${landingPath}`;
    }

    if (landingPath) {
      return `/api/v1/${siteTypePath}/${siteId}/landings/${landingPath}`;
    }

    return `/api/v1/${siteTypePath}/${siteId}/${sitePage}`;
  },

  fetchSiteSpeakers({ siteId, searchParams }) {
    const checkedQueryParams = filterValidStreamsAsQueryString(searchParams);
    return `/api/v1/events/${siteId}/speakers${checkedQueryParams}`;
  },
  fetchSiteTrainers({ siteId, searchParams }) {
    const checkedQueryParams = filterValidStreamsAsQueryString(searchParams);
    return `/api/v1/courses/${siteId}/trainers${checkedQueryParams}`;
  },
  fetchSitePartners({ siteId, siteTypePath }) {
    return `/api/v1/${siteTypePath}/${siteId}/third-party-partners`;
  },
  fetchSiteSpeakerDetails({ siteId, name }) {
    return `/api/v1/events/${siteId}/speakers/${name}`;
  },
  fetchSiteTrainerDetails({ siteId, name }) {
    return `/api/v1/courses/${siteId}/trainers/${name}`;
  },
  fetchSiteSponsorDetails({ siteId, siteTypePath, name }) {
    return `/api/v1/${siteTypePath}/${siteId}/sponsors/${name}`;
  },

  fetchAgenda({ siteTypePath, siteId, agendaDay, queryParams }) {
    const checkedQueryParams = filterValidStreamsAsQueryString(queryParams);
    return `/api/v1/${siteTypePath}/${siteId}/agenda/${agendaDay}${checkedQueryParams}`;
  },
  fetchCoLoAgenda({ siteTypePath, siteId, agendaDay, query }) {
    const checkedQueryParams = filterValidStreamsAsQueryString(query);
    return `/api/v1/${siteTypePath}/${siteId}/colo-agenda/${agendaDay}${checkedQueryParams}`;
  },
  fetchDownloadableAgenda({ siteTypePath, siteId, queryParams }) {
    return `/api/v1/${siteTypePath}/${siteId}/downloadableAgenda${queryParams}`;
  },

  fetchHubSummaries({ apiHost, tenantId }) {
    return `${apiHost}/api/v1/hubs/${tenantId}/siteSummaries`;
  },

  fetchPortfolioSummaries({ apiHost, tenantId, query }) {
    return `${apiHost}/api/v1/portfolios/${tenantId}/siteSummaries${query}`;
  },

  fetchSiteSummaries({ apiHost, siteTypePath, siteId }) {
    return `${apiHost}/api/v1/${siteTypePath}/${siteId}/baseSettings`;
  },

  fetchBookingClosedMessage({ siteTypePath, siteId }) {
    return `/api/v1/${siteTypePath}/${siteId}/booking-closed-message`;
  },

  fetchBrandHubBaseSettings({ apiHost, brandHubId }) {
    return `${apiHost}/api/v1/brand-hub/${brandHubId}/baseSettings`;
  },

  fetchAudienceHubBaseSettings({ apiHost, audienceHubId }) {
    return `${apiHost}/api/v1/audience-home/${audienceHubId}/baseSettings`;
  },
};

export default v1;
