import { ProductsSelections } from '../../../store/features/packages/types';
import { getSingleProductIdsList } from '../getPreselectedPackageFormValues';
import { canIncludeBundledProductToPackage } from '../canIncludeBundledProductToPackage';

export const getProductIdsFromPackageFormValues = (
  values: ProductsSelections,
  bundledProductsMap?: Record<string, string[]>,
) => {
  const { productSingleIdsMap, productMultipleIds } = values;
  const singleProductIds = getSingleProductIdsList(productSingleIdsMap);
  const allProductsIds = [...productMultipleIds, ...singleProductIds];

  if (!bundledProductsMap) {
    return allProductsIds;
  }

  return allProductsIds.filter((productId) => {
    return canIncludeBundledProductToPackage(
      productId,
      allProductsIds,
      bundledProductsMap,
    );
  });
};
