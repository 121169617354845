export function canIncludeBundledProductToPackage(
  productId: string,
  allProductIds: string[],
  bundledProductsMap: Record<string, string[]>,
) {
  const bundledProducts = bundledProductsMap[productId];

  return (
    !bundledProducts ||
    allProductIds.some((value) => bundledProducts.includes(value))
  );
}
