import { SiteLocales } from '../../types';

const localesMatcher: Record<string, string> = {
  [SiteLocales.en]: 'en',
  [SiteLocales.de]: 'de',
  [SiteLocales.fr]: 'fr',
  [SiteLocales.es]: 'es',
  [SiteLocales.pt]: 'pt',
  [SiteLocales.br]: 'pt-br',
  [SiteLocales.ru]: 'ru',
  [SiteLocales.vn]: 'vi',
  [SiteLocales.cf]: 'fr-ca',
  [SiteLocales.ar]: 'ar',
  [SiteLocales.cn]: 'zh-cn',
  [SiteLocales.ja]: 'ja',
  [SiteLocales.ko]: 'ko',
  [SiteLocales.zh]: 'zh',
};

export const getCorrectLocale = (locale: string) => {
  return localesMatcher[locale] || locale;
};
