import { DownloadAnchor } from './DownloadAnchor';
import { Button } from '../../../../../shared/Button';

import styles from './DownloadFiles.module.scss';

export interface DownloadFilesProps {
  urls: string[];
  linkView?: boolean;
  linkText?: string;
  showTitle?: boolean;
}

export function DownloadFiles({
  urls,
  linkView,
  linkText,
  showTitle,
}: DownloadFilesProps) {
  return (
    <div className={styles.wrapper}>
      {showTitle && <h5 className={styles.heading}>Downloads: </h5>}
      <div className={styles.body}>
        {urls.map((url) => {
          const urlChunks = url.split('/');
          const downloadUrl = `/uploads/${url}`;
          const linkName = linkText
            ? linkText
            : urlChunks[urlChunks.length - 1].slice(37) || 'File';

          if (linkView) {
            return (
              <DownloadAnchor key={url} to={downloadUrl}>
                {linkName}
              </DownloadAnchor>
            );
          }

          return (
            <Button
              key={url}
              to={downloadUrl}
              size="medium"
              startIcon="get_app"
            >
              {linkName}
            </Button>
          );
        })}
      </div>
    </div>
  );
}
