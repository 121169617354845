import { Helmet } from 'react-helmet';

import { useRootSelector } from '../../../../../../store/hooks';
import { selectAudienceHubOptions } from '../../../store/features/options';
import { selectArticleHome } from '../../../../../../store/features/articles/home';

import StaticPageLayout from '../../../layouts/StaticPageLayout';
import ArticleRightSidebar from './ArticleRightSidebar';
import ArticleFooter from './ArticleFooter';
import ArticleContent from './ArticleContent';
import FloatingEmailOptInModule from '../../../../shared/FloatingEmailOptInModule';

import styles from './ArticleHome.module.scss';

export function ArticleHome() {
  const {
    data: { communityPath: audiencePath },
  } = useRootSelector(selectArticleHome);
  const {
    data: { newsletterSignUp, promotionalSignUp, subBrandName },
    data,
  } = useRootSelector(selectAudienceHubOptions);

  const signupFormShown =
    newsletterSignUp.articleEnabled || promotionalSignUp.articleEnabled;

  return (
    <StaticPageLayout audiencePath={audiencePath}>
      <Helmet>
        <meta property="og:type" content="article" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className={styles.wrapper}>
        <div className="container">
          <div className="row">
            <article
              className="col-xs-12 col-md-8"
              itemScope
              itemType="https://schema.org/Article"
            >
              <ArticleContent />
            </article>
            <aside className="col-xs-12 col-md-3 col-md-offset-1 hidden-xs hidden-sm">
              <ArticleRightSidebar />
            </aside>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <ArticleFooter />
            </div>
          </div>
        </div>
      </div>
      {signupFormShown && (
        <FloatingEmailOptInModule
          pageType="article"
          permissionTextName={subBrandName}
          hubOptions={data}
        />
      )}
    </StaticPageLayout>
  );
}
