import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import SocialIcon from '../../../../shared/SocialIcon';
import Link from '../../../../shared/Link/Link';

import './SiteUpperFooter.scss';

export function SiteUpperFooter(props) {
  const {
    bgColor,
    styleVariant,
    data: { hashtag, social = [], contact = {} } = {},
  } = props;

  const { t } = useTranslation();

  const socialList = social.map((item, i) => {
    return (
      <li key={i} className="footer-upper-contacts__social-item">
        <Link
          to={item.url}
          className="footer-upper-contacts__social-link"
          external
        >
          <SocialIcon
            type={item.type}
            style={{ color: bgColor }}
            className="footer-upper-contacts__social-icon"
          />
          {item.text}
        </Link>
      </li>
    );
  });

  const phoneLink =
    'tel:' +
    (contact.phoneNumber || '').replace(/[a-zA-Z\s()\-.]/g, '').split(',')[0];
  const emailLink = 'mailto:' + contact.email;

  return (
    <div
      className={cn('footer-upper', {
        [`${styleVariant?.toLowerCase()}_footer-upper`]: styleVariant,
      })}
      style={{ backgroundColor: bgColor }}
      data-testid="upper-footer"
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="footer-upper-hashtag">
              <Link
                to={`https://twitter.com/hashtag/${hashtag}`}
                className="footer-upper-hashtag__link"
                external
              >
                {hashtag && '#' + hashtag}
              </Link>
            </div>
            <div className="footer-upper-contacts">
              <ul className="footer-upper-contacts__social-list">
                {socialList}
              </ul>
              <ul className="footer-upper-contacts__mail-phone-list">
                {contact.email && (
                  <li className="footer-upper-contacts__mail-phone-item">
                    <span className="footer-upper-contacts__mail-phone-item-title">
                      {t('event.email')}
                    </span>
                    <Link
                      to={emailLink}
                      className="footer-upper-contacts__mail-phone-link"
                      external
                    >
                      {contact.email}
                    </Link>
                  </li>
                )}
                {contact.phoneNumber && (
                  <li className="footer-upper-contacts__mail-phone-item">
                    <span className="footer-upper-contacts__mail-phone-item-title">
                      {t('event.telephone')}
                    </span>
                    <Link
                      to={phoneLink}
                      className="footer-upper-contacts__mail-phone-link"
                      external
                    >
                      {contact.phoneNumber}
                    </Link>
                  </li>
                )}

                {contact.whatsAppUrl && (
                  <li className="footer-upper-contacts__mail-phone-item">
                    <span className="footer-upper-contacts__mail-phone-item-title">
                      {t('event.whatsapp')}
                    </span>
                    <Link
                      to={contact.whatsAppUrl}
                      className="footer-upper-contacts__mail-phone-link"
                      external
                    >
                      {contact.whatsAppText || t('event.whatsapp')}
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SiteUpperFooter.propTypes = {
  data: PropTypes.shape({
    hashtag: PropTypes.string,
    social: PropTypes.array,
    contact: PropTypes.object,
  }),
  bgColor: PropTypes.string,
  styleVariant: PropTypes.string,
};

export default SiteUpperFooter;
