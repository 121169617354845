import qs from 'qs';
import { createSelector, PayloadAction } from '@reduxjs/toolkit';
import type { GenericState } from '../../../types';
import type { RootState } from '../../store';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../generic';
import { selectPageConfig } from '../pageConfig';

export type titleSizeType = 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6';

export interface Details {
  live: boolean;
  showPageHeader: boolean;
  titleSize: titleSizeType;
  pageTitle: string;
  title?: string;
  metaDescription?: string;
}

export interface SiteArticle {
  id: string;
  path: string;
  title: string;
  summary: string;
  imageUrl: string;
  datePublished: string;
  authorName: string;
  tags: string[];
  externalLinkEnabled: boolean;
  externalLink: string;
}

export interface SiteArticles {
  data: {
    path: string;
    details: Details;
    news: SiteArticle[];
  };
}

export interface SiteArticlesParams {
  queries?: {
    page?: number;
    pageSize?: number;
  };
}

export const fetchSiteArticles = createGenericAsyncThunk<
  SiteArticles,
  SiteArticlesParams
>(
  'siteArticles/fetchSiteArticles',
  async ({ queries }, { extra, rejectWithValue, getState }) => {
    const { siteId, siteTypePath } = selectPageConfig(getState());
    const queryString = qs.stringify(queries, {
      addQueryPrefix: true,
    });

    const response = await extra.ajax.get(
      `/caas/content/api/v1/${siteTypePath}/${siteId}/news-insights${queryString}`,
      {
        headers: {
          'Accept-Language': extra.i18n.language,
          'Published-State': extra.headers['Published-State'],
        },
      },
    );
    const data: SiteArticles = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export const fetchSiteArticlesData = createGenericAsyncThunk<
  SiteArticles,
  SiteArticlesParams
>(
  'siteArticles/fetchSiteArticlesData',
  async (params, { extra, rejectWithValue, getState }) => {
    const { siteId, siteTypePath } = selectPageConfig(getState());
    const { queries } = params;
    const query = qs.stringify(queries, {
      addQueryPrefix: true,
    });

    const response = await extra.ajax.get(
      `/caas/content/api/v1/${siteTypePath}/${siteId}/news-insights${query}`,
      {
        headers: {
          'Accept-Language': extra.i18n.language,
          'Published-State': extra.headers['Published-State'],
        },
      },
    );
    const data: SiteArticles = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const articleSiteSlice = createGenericSlice({
  name: 'siteArticles',
  initialState: {
    status: 'idle',
    data: {
      data: {
        news: [],
      },
    } as unknown as SiteArticles,
  } as GenericState<SiteArticles>,
  reducers: {
    updatePreloadedArticlesList(
      state,
      payloadAction: PayloadAction<SiteArticles>,
    ) {
      const {
        payload: {
          data: { news: loadedArticles },
        },
      } = payloadAction;
      const currentArticles = state.data.data.news;

      if (loadedArticles.length > 0 && currentArticles) {
        state.data.data.news = currentArticles.concat(loadedArticles);
      }
    },
  },
  extraReducers: (builder) => {
    createGenericBuilderCases<SiteArticles, SiteArticlesParams>(
      builder,
      fetchSiteArticles,
    );
  },
});

export const selectSiteArticlesData = (state: RootState) =>
  state.site.articles.data.data;

export const selectSiteFeaturedArticlesList = createSelector(
  [selectSiteArticlesData],
  ({ news }) => news.slice(0, 7),
);

export const selectSiteArticlesList = createSelector(
  [selectSiteArticlesData],
  ({ news }) => news.slice(7),
);

export const { start, fulfilled, rejected, updatePreloadedArticlesList } =
  articleSiteSlice.actions;

export default articleSiteSlice.reducer;
