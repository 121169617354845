import React, { HTMLAttributes } from 'react';
import { HubModuleCTA, TitleSizeType } from '../../../../../../types';
import { Heading, Paragraph, Line } from '../index';
import { CallToAction } from '../../../CallToActionGroup';
import styles from './ModuleHeader.module.scss';

export interface ModuleHeaderProps extends HTMLAttributes<HTMLElement> {
  title?: string;
  titleSize?: TitleSizeType;
  text?: string;
  link?: HubModuleCTA;
  lineAfterHeading?: boolean;
  lineAfterParagraph?: boolean;
}

export function ModuleHeader({
  title,
  titleSize = 'H2',
  text,
  link,
  lineAfterHeading,
  lineAfterParagraph,
  className,
}: ModuleHeaderProps) {
  return (
    <div className={className}>
      {title && (
        <div className={styles.heading}>
          <Heading titleSize={titleSize}>{title}</Heading>
        </div>
      )}
      {lineAfterHeading && <Line className={styles.line} />}
      {text && (
        <div className={styles.paragraph}>
          <Paragraph text={text} className="formatted-text" />
        </div>
      )}
      {lineAfterParagraph && <Line className={styles.line} />}
      {link?.type && (
        <CallToAction
          link={link}
          variant="contained"
          className={styles.ctaButton}
          data-testid="hub-module-link"
        />
      )}
    </div>
  );
}
