import React from 'react';
import ReactDOM from 'react-dom/client';
import qs from 'qs';
import fetch from 'cross-fetch';
import { loadableReady } from '@loadable/component';
import { osName, browserName } from 'react-device-detect';
import i18n from './i18n';
import clientConfig from './clientConfig';
import Root from './components/common/Root/Root';
import App from './components/common/App/App';
import reportWebVitals from './reportWebVitals';
import configureStore from './store/configureStore';
import GTM from './utils/initialGTM';
import { createEmotionCache } from './utils/createEmotionCache';

if (typeof window.fetch === 'undefined') {
  window.fetch = fetch;
}

/**
 * Remove double slash
 */
if (document.location.pathname.search(/\/\/+/) > -1) {
  const { pathname, search } = document.location;
  window.history.replaceState(null, '', pathname.replace(/\/+/g, '/') + search);
}

/**
 * Remove noCache query param after redirect from login page
 */
if (document.location.search.search(/\?noCache=/) > -1) {
  const { pathname, search } = document.location;
  const qParams = qs.parse(search.slice(1));
  delete qParams.noCache;

  window.history.replaceState(
    null,
    '',
    pathname +
      qs.stringify(qParams, {
        addQueryPrefix: true,
      }),
  );
}

document.body.classList.add(osName.replace(/ /g, '-').toLowerCase());
document.body.classList.add(browserName.replace(/ /g, '-').toLowerCase());

window.addEventListener('load', () => {
  document.body.classList.add('loaded');

  GTM.init();
});

const store = configureStore(
  {
    i18n,
    location: document.location,
  },
  clientConfig,
);
const theme = window.__THEME_CONFIG__;
const emotionCache = createEmotionCache();

const view = (
  <Root store={store} theme={theme} i18n={i18n} emotionCache={emotionCache}>
    <App />
  </Root>
);
const rootElement = document.getElementById('root');

loadableReady(() => {
  ReactDOM.hydrateRoot(rootElement, view);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
