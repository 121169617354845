import React from 'react';
import { HTMLtoJSX } from '../../../../../../helpers';
import styles from './ProductModuleHeading.module.scss';
import { HeadingSizeTags, TitleSizeType } from '../../../../../../types';

export interface ProductModuleHeadingProps {
  heading: React.ReactNode;
  titleSize?: TitleSizeType;
  text?: React.ReactNode;
}

export function ProductModuleHeading(props: ProductModuleHeadingProps) {
  const { heading, titleSize, text } = props;
  const HeadingTag = titleSize ? HeadingSizeTags[titleSize] : 'div';

  return (
    <div className={styles.wrapper}>
      <HeadingTag className={styles.heading}>{heading}</HeadingTag>
      {text && <div className={styles.text}>{HTMLtoJSX(text)}</div>}
    </div>
  );
}
