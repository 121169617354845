import React from 'react';
import Parser from 'html-react-parser';
import { isMobile } from 'react-device-detect';
import lozad from 'lozad';
import qs from 'qs';
import clientConfig from '../clientConfig';

export * from './enums';
export * from './pageScrollControl';
export * from './paymentJourney';
export * from './removeTagsFromString';
export * from './getCorrectLocale';
export * from './divideArrayIntoParts';
export * from './concatPath';
export * from './getClientConfig';
export * from './date';
export * from './getNameFormatTranslation';
export * from './beautifyUrl';

export function isEnv(env) {
  if (!env) {
    return global.__CLIENT_CONFIG__.env;
  }

  return global.__CLIENT_CONFIG__.env === env.trim().toLowerCase();
}

export function isBrowser() {
  return typeof window !== 'undefined';
}

export function inIframe() {
  return isBrowser() && window.self !== window.top;
}

export function isNode() {
  return typeof window === 'undefined';
}

export function isProduction() {
  return process.env.NODE_ENV === 'production';
}

export function isDevelopment() {
  return process.env.NODE_ENV === 'development';
}

export function isBoolean(variable) {
  return typeof variable === 'boolean';
}

export function isFunction(callback) {
  return typeof callback === 'function';
}

export function isNumber(variable) {
  return typeof variable === 'number';
}

export function isString(variable) {
  return typeof variable === 'string';
}

export function isUndefined(variable) {
  return typeof variable === 'undefined';
}

export function hasValue(value) {
  return value !== undefined && value !== null && value !== '';
}

export function isAbsoluteUrl(path) {
  return /^https?:\/\//i.test(path);
}

export function isMailToUrl(path) {
  return /^mailto:/i.test(path);
}

export function isTelephoneUrl(path) {
  return /^tel:/i.test(path);
}

export function isFileUrl(path) {
  return /\.[a-zA-Z]{3,4}$/.test(path);
}

export function isPreview(hostname) {
  return hostname.search(/coreinforma.com/i) !== -1;
}

export function isLocalhostEnv(location) {
  return Boolean(
    location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
      ),
  );
}

export function canCookieBeSecure() {
  return process.env.NODE_ENV === 'production' && isEnv() !== 'local';
}

export function HEXtoRGBA(color = '', alpha = 1) {
  const hex = color.replace('#', '').trim().toLowerCase();

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  return `rgba(${r},${g},${b},${alpha})`;
}

export function HTMLtoJSX(htmlString) {
  return typeof htmlString === 'string'
    ? Parser(
        upgradeTable(removeNonBreakSpace(removeRedundantStyles(htmlString))),
      )
    : null;
}

export function upgradeTable(htmlString) {
  return htmlString
    .replace(/<table/g, '<div class="table-wrapper"><table')
    .replace(/<\/table>/g, '</table></div>');
}

export function removeNonBreakSpace(htmlString) {
  const regex = /&nbsp;/g;

  return htmlString.replace(regex, ' ');
}

export function removeRedundantStyles(htmlString) {
  const regex = /font-size: 14px; font-family: Roboto;/g;

  return htmlString.replace(regex, '');
}

export function renderSVGString(svg) {
  return HTMLtoJSX(svg);
}

export function renderHTMLString(html, withStyle = true) {
  return (
    html && (
      <span className={`${withStyle ? 'formatted-text' : ''}`}>
        {HTMLtoJSX(html)}
      </span>
    )
  );
}

export function renderCSSString(css) {
  return <style>{HTMLtoJSX(css)}</style>;
}

export function encodeItem(item) {
  return encodeURIComponent(item).replace(/%20/g, '+');
}

export function decodeItem(item) {
  return decodeURIComponent(item).replace(/\+/g, ' ');
}

export function stringToNumber(str) {
  return +str.replace(/[^\d.]/g, '');
}

export function replaceHtmlCharacter(strOrArray, htmlCharacter, symbol) {
  const regExp = new RegExp(htmlCharacter, 'g');

  if (Array.isArray(strOrArray)) {
    return strOrArray.map((item) => {
      if (item && item.indexOf(htmlCharacter) !== -1) {
        return item.replace(regExp, symbol);
      }
      return item;
    });
  } else if (typeof strOrArray === 'string') {
    return strOrArray.replace(regExp, symbol);
  } else {
    return strOrArray;
  }
}

export function addSlashAtEndOfString(str = '') {
  return str.replace(/(\/+)?$/, '/');
}

export function addQueryParamsToUrl(url, params = {}, shouldReplace) {
  const [outputUrl, queryString] = url.split('?');
  const queryParams = qs.parse(queryString);

  Object.keys(params).forEach((param) => {
    if (shouldReplace || !queryParams[param]) {
      queryParams[param] = params[param];
    }
  });

  const outputQueryString = qs.stringify(queryParams, {
    encode: true,
    indices: false,
    addQueryPrefix: true,
  });

  return outputUrl + outputQueryString;
}

export function stringifyQueryParams(
  params,
  questionMark = true,
  stringifyOptions,
) {
  let query = params;
  let qsOptions = {
    encode: true,
  };

  if (stringifyOptions) {
    qsOptions = { ...qsOptions, ...stringifyOptions };
  }

  if (!isString(query)) {
    query = qs.stringify(query, qsOptions);
  }

  if (questionMark && query.length > 1 && !/^\?/.test(query)) {
    query = '?' + query;
  }

  return query;
}

export function requestFullscreen(elem) {
  if (elem.requestFullscreen) {
    return elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    return elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    return elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    return elem.msRequestFullscreen();
  }

  return false;
}

export function exitFullscreen() {
  if (document.exitFullscreen) {
    return document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    return document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    return document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    return document.msExitFullscreen();
  }

  return false;
}

export function throttle(fn, ms) {
  let isThrottled = false;
  let savedArgs;
  let savedThis;

  function wrapper() {
    if (isThrottled) {
      savedArgs = arguments;
      savedThis = this;
      return;
    }

    fn.apply(this, arguments);

    isThrottled = true;

    setTimeout(() => {
      isThrottled = false;

      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  }

  return wrapper;
}

export function compare(a, b) {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  }

  return 0;
}

export function getTenantIdFromUrl(hostname = '') {
  /**
   * For tests and if localhost is used in development
   */
  if (isLocalhostEnv({ hostname })) {
    return process.env.TENANT || 'INFORMACONNECT';
  }

  return hostname.split('.').slice(-2, -1)[0].toLowerCase() || '';
}

export function getCookieDomainFromUrl(hostname = '') {
  return '.' + hostname.split('.').slice(-2).join('.');
}

export function isTableBlockLarge(index) {
  return [3, 9].indexOf(index % 10) > -1;
}

export function splitListIntoChunks(list = [], size = 3) {
  const chunks = [];
  const newList = Object.assign([], list);

  while (newList.length > 0) {
    chunks.push(newList.splice(0, size));
  }

  return chunks;
}

export function createCanonicalUrl(pathname, sub, domain) {
  let path = pathname;
  // adding Slash at the start of path
  if (typeof path === 'string' && path[0] !== '/') {
    path = '/' + path;
  }
  // adding slash at the end of path
  path = addSlashAtEndOfString(path);

  return `https://${
    sub ? sub.toLowerCase() + (isEnv('prod') ? '.' : '-') : ''
  }${domain}${path || ''}`;
}

export function lazyLoadingRefresh(init, lazyLoadingClassName = '.lazy') {
  if (isNode()) return;

  const pluginConfig = {
    rootMargin: '600px 0px',

    /**
     * Custom function definition to load element
     */
    load(el) {
      const dataSrc = el.getAttribute('data-src');
      const dataBackgroundImage = el.getAttribute('data-background-image');

      /**
       * Image tag (<img />)
       */
      if (dataSrc) {
        const { url } = processImgixQueryParams(dataSrc);
        el.src = url;
      }

      /**
       * Background image
       */
      if (dataBackgroundImage) {
        const { url } = processImgixQueryParams(dataBackgroundImage);
        el.style.backgroundImage = `url(${url})`;
      }
    },
  };

  window.lazyLoadingObserver =
    window.lazyLoadingObserver || lozad(lazyLoadingClassName, pluginConfig);

  if (init) {
    /**
     * Trigger on App init
     */
    window.lazyLoadingObserver.observe();
  } else {
    /**
     * Trigger manually if required
     */
    document.querySelectorAll(lazyLoadingClassName).forEach((el) => {
      el.setAttribute('data-loaded', 'false');
    });
    window.lazyLoadingObserver.observe();
  }
}

export function optimizeImgixImages(className = '.no-lazy') {
  document.querySelectorAll(className).forEach((el) => {
    const imageSrc = el.getAttribute('src');

    if (imageSrc) {
      const { url } = processImgixQueryParams(imageSrc);
      el.src = url;
    }
  });
}

export function processImgixQueryParams(imageUrl) {
  const [imageSrc, imageQueryString] = imageUrl.split('?');
  const imageQueryParams = qs.parse(imageQueryString);

  if (imageQueryString) {
    const {
      devicePixelRatio,
      screen: { width: screenWidth },
    } = window;

    if (!imageQueryParams.w && !imageQueryParams.h) {
      imageQueryParams.w = screenWidth.toString();
    }

    if (isMobile) {
      imageQueryParams.dpr = '5';
    } else {
      imageQueryParams.dpr = devicePixelRatio.toString();
    }
  }

  const queryString = qs.stringify(imageQueryParams, {
    encode: true,
    indices: false,
    addQueryPrefix: true,
  });

  return {
    src: imageSrc,
    url: imageSrc + queryString,
    queryParams: imageQueryParams,
    queryString,
  };
}

export function getImgixUrl(tenantId, src, imgixParams, preset) {
  const imgixHost = clientConfig.imgix[tenantId]
    ? clientConfig.imgix[tenantId].host
    : clientConfig.imgix.DEFAULT.host;

  if (!src || isAbsoluteUrl(src) || !imgixHost) {
    return src || preset || undefined;
  }

  const isGif = /.\.gif$/i.test(src);

  const defaultParams = {
    auto: isGif ? 'format,compress' : 'format',
    fit: 'max',
  };
  const customParams = isString(imgixParams) ? qs.parse(imgixParams) : {};

  const queryParams = qs.stringify(Object.assign(defaultParams, customParams), {
    encode: true,
    indices: false,
    addQueryPrefix: true,
  });

  return imgixHost + src + queryParams;
}

export function toTwoDigitFormat(digit) {
  return `0${digit}`.slice(-2);
}

export function removeSitePathFromRoute(pattern) {
  return pattern.replace(/^\/:sitePath/i, '') || '/';
}

export function removeBrandPathFromRoute(pattern) {
  return pattern.replace(/\/:brandPath/i, '').replace(/\/:brandSubPath/i, '');
}

export function redirect(url) {
  document.location.replace(url);
}

export function goTo(url) {
  document.location.href = url;
}

export function reload() {
  document.location.reload();
}

export function fixPath(path) {
  return path.replace(/^\/+/, '/').replace(/\/$/, '').replace(/\/+/g, '/');
}
