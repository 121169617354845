import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../helpers';

const HeadingWrapper = styled.h2`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.siteBuilderModules.heading)}
`;

export const Heading = ({
  content,
  as = 'h2',
  headingClass,
  children,
  testId,
}) => (
  <HeadingWrapper
    as={as.toLowerCase()}
    data-testid={testId}
    className={headingClass}
  >
    {content || children}
  </HeadingWrapper>
);

Heading.propTypes = {
  content: PropTypes.string,
  headingClass: PropTypes.string,
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  children: PropTypes.node,
  testId: PropTypes.string,
};

const StyledHeadingWrapper = styled.div`
  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles?.elements?.heading)}
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles?.elements?.heading)}
  ${({ theme, visualStyles, align }) =>
    renderStyledElementStyles(
      theme,
      visualStyles?.elements?.heading?.alignment?.[align],
    )}
`;

export const StyledHeading = ({
  content,
  align,
  displayStyles,
  visualStyles,
  as,
  testId,
}) => (
  <StyledHeadingWrapper
    align={align}
    displayStyles={displayStyles}
    visualStyles={visualStyles}
  >
    <Heading content={content} as={as} testId={testId} />
  </StyledHeadingWrapper>
);

StyledHeading.propTypes = {
  align: PropTypes.string,
  content: PropTypes.string,
  as: PropTypes.string,
  testId: PropTypes.string,
};

StyledHeading.defaultProps = {
  displayStyles: null,
  visualStyles: null,
};
